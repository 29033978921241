import React from "react";
import Testimonial from "../components/Testimonial";
import Home from "../components/Home";
import Description from "../components/Description";

function HomePage() {
  return (
    <div>
      <Home></Home>
      <Description></Description>
      <Testimonial></Testimonial>
    </div>
  );
}

export default HomePage;
