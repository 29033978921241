import React from "react";
import "./Testimonial.css";

function Testimonial() {
  return (
    <div>
      <h2 className="testimonial-heading">Testimonials</h2>
      <div className="testimonial-container">
        <div className="row">
          <div className="column">
            <div className="card">
              <h3>Shabir M - Tonawanda, NY</h3>
              <p>
                Ali is a great asset to any contractor/client relationship - he
                created us a brand new bathroom within our budget. The materials
                used were also of our choosing & the punctuality of the workers
                was very reliable - I highly recommend him for custom jobs
                around the house.
              </p>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <h3>Mark & Jessica - East Amherst, NY</h3>
              <p>
                We needed a lot of work done when we ran into Ali at the local
                home depot - he came over on a days notice & moved his schedule
                to adapt to our kitchen & flooring renovation - we were thrilled
                when him & his team did all the work in just 1 week, good
                contractors like Ali Zenki are hard to find.
              </p>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <h3>Jim & Connie - Buffalo, NY</h3>
              <p>
                Ali has been our contractor for over 5 years. I rate him highly
                because he accomodates our requests in the busiest seasons, we
                needed our roof done & he worked within our budget to finish the
                job within 10 days - I highly recommend him if youre in the
                Buffalo Rochester Area.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
