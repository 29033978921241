import React from "react";
import { Link } from "react-router-dom";
import "./NavBar.css";

function NavBar() {
  return (
    <div>
      <h1 className="company-name">Zenki Construction</h1>
      <nav className="nav-links">
        <Link to="/">Home</Link> <Link to="/gallery">Gallery</Link>{" "}
        <Link to="/contact">Contact</Link>
      </nav>
    </div>
  );
}

export default NavBar;
