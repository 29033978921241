import React from "react";
import "./Roof.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from "../assets/residentialroofingprojects/PHOTO-2023-01-21-17-36-28 2.JPG";
import image2 from "../assets/residentialroofingprojects/PHOTO-2023-01-21-17-36-32 2.JPG";
import image3 from "../assets/residentialroofingprojects/0.jpg";
import "./Roof.css";

function Roof() {
  return (
    <div className="roof-wrapper">
      <h2>Remodeled Roof</h2>
      <Carousel infiniteLoop className="roof-carousel">
        <div>
          <img alt="roof" src={image1} />
        </div>
        <div>
          <img alt="roof" src={image2} />
        </div>
        <div>
          <img alt="roof" src={image3} />
        </div>
      </Carousel>
    </div>
  );
}

export default Roof;
