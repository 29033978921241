import React from "react";
import Kitchen from "../components/Kitchen";
import Bathroom from "../components/Bathroom";
import Roof from "../components/Roof";

function Gallery() {
  return (
    <div>
      <Bathroom></Bathroom>
      <Roof></Roof>
      <Kitchen></Kitchen>
    </div>
  );
}

export default Gallery;
