import React from "react";
import "./Home.css";

function Home() {
  return (
    <div className="page1">
      <div className="Homepage">
        <h2>A HOME FOR EVERY LIFE</h2>
        <hr></hr>
        <h3>FOR EVERY STYLE</h3>
      </div>
    </div>
  );
}

export default Home;
