import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div className="page4">
      <div className="contact-container">
        <h2 className="contact-header">Contact</h2>
        <label>Email:</label>
        <a href="mailto:info@zenkiconstruction.com" className="contact-link">
          info@zenkiconstruction.com
        </a>
        <label>Phone:</label>
        <a href="tel:716-994-3929" className="contact-link">
          716-994-3929
        </a>
      </div>
    </div>
  );
}

export default Contact;
