import React from "react";
import { Carousel } from "react-responsive-carousel";
import image2 from "../assets/bathroomcarousel/PHOTO-2023-01-21-17-38-15.jpg";
import image3 from "../assets/bathroomcarousel/PHOTO-2023-01-21-17-38-16.jpg";
import image4 from "../assets/bathroomcarousel/PHOTO-2023-01-21-17-38-19.jpg";
import "./Bathroom.css";

function Bathroom() {
  return (
    <div className="bathroom-container">
      <h2 className="bathroom-heading">Remodeled Bathroom</h2>
      <Carousel infiniteLoop className="carousel">
        <div>
          <img alt="remodeled bathroom" src={image2} />
        </div>
        <div>
          <img alt="remodeled bathroom" src={image3} />
        </div>
        <div>
          <img alt="remodeled bathroom" src={image4} />
        </div>
      </Carousel>
    </div>
  );
}

export default Bathroom;
