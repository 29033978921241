import React from "react";
import "./Description.css";

function Description() {
  return (
    <div className="description-container">
      <div className="description-row">
        <div className="description-column">
          <div className="description-card">
            <h2 className="description-h2">
              Zenki Construction Corporation - Proudly serving the Buffalo area
              with the best construction projects for 20+ years
            </h2>
          </div>
        </div>
        <div className="description-column">
          <div className="description-card">
            <h3 className="description-h3">
              Residential & Commercial services offered
            </h3>
            <ul>
              <li>
                Exterior - Fencing, roofing/siding, paint, decking,
                doors/windows & custom home additions
              </li>
              <li>
                Interior - Flooring, carpeting, tiles, full kitchens, bathrooms,
                woodwork & all custom renovations
              </li>
            </ul>
            <p className="description-p">
              <strong>
                Call us at{" "}
                <a href="tel:716-994-3929" id="phone-number">
                  716-994-3929
                </a>{" "}
                to get a quick & transparent estimate
              </strong>
            </p>
            <h3 className="description-h3">Customer Benefits</h3>
            <ul>
              <li>Sample quote project images available on request</li>
              <li>Discounts available for combined job quotes</li>
              <li>Well-rounded expertise for all work categories</li>
              <li>Priority given to long term customers for their loyalty</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;
