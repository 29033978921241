import React from "react";
import "./Kitchen.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from "../assets/kitchens/PHOTO-2023-01-21-17-38-12 2.JPG";
import image2 from "../assets/kitchens/PHOTO-2023-01-21-17-38-12.JPG";
import image3 from "../assets/kitchens/PHOTO-2023-01-21-17-38-13.JPG";
import image4 from "../assets/kitchens/PHOTO-2023-01-21-17-38-18 2.JPG";
import image5 from "../assets/kitchens/PHOTO-2023-01-21-17-38-18.JPG";

function Kitchen() {
  return (
    <div className="kitchen-wrapper">
      <h2>Remodeled Kitchen</h2>
      <Carousel infiniteLoop className="kitchen">
        <div>
          <img alt="kitchen" src={image1} />
        </div>
        <div>
          <img alt="kitchen" src={image2} />
        </div>
        <div>
          <img alt="kitchen" src={image3} />
        </div>
        <div>
          <img alt="kitchen" src={image4} />
        </div>
        <div>
          <img alt="kitchen" src={image5} />
        </div>
      </Carousel>
    </div>
  );
}

export default Kitchen;
